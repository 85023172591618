import DropZone from '@components/DropZone'
import { Spinner } from '@components/Spinner'
import { useNotifications } from '@utils/useNotifications'

import { Teams } from './teams'

var Tasks = {
  disableButton: function (buttonElement) {
    buttonElement.prop('disabled', true)
  },

  enableButton: function (buttonElement) {
    buttonElement.prop('disabled', false)
  },

  changeStatus: function (taskId, statusId) {
    let endedAt = false
    if (typeof moment !== 'undefined') {
      endedAt = moment().format('YYYY-MM-DD H:mm:ss')
    }

    $.ajax({
      url: Routing.generate('app_task_status', { taskId: taskId }),
      type: 'POST',
      data: { statusId: statusId, endedAt: endedAt },
      dataType: 'JSON',
      success: function (response) {
        if (response.success) {
          window.location.reload()
        }
      },
    })
  },
  loadDeadlineTasks: function () {
    $.ajax({
      url: Routing.generate('app_user_tasks_deadline'),
      type: 'GET',
      dataType: 'JSON',
      success: function (response) {
        if (response.success) {
          const deadlineTasksBlock = $('.tasks-deadline-menu')
          if (deadlineTasksBlock.length && response.html) {
            deadlineTasksBlock.replaceWith(response.html)
            $('.dropdown-toggle').dropdown()
          }
        }
      },
    })
  },
  removeAttachment: function (taskId, attachmentId) {
    $.ajax({
      url: Routing.generate('app_task_attachment_remove', { taskId: taskId, attachmentId: attachmentId }),
      type: 'POST',
      dataType: 'JSON',
      success: function (response) {
        // if (response.success) {
        // }
      },
    })
  },
  prepareFormAttachments: function (formData) {
    this.DropZone.files.forEach((value, index) => {
      formData.append('task[fileUploadAttachments][]', value)
    })

    this.DropZone.images.forEach((value, index) => {
      formData.append('task[fileUploadAttachments][]', value)
    })

    const formattedData = $('#task_shouldBeFinished').datepicker('getDate')
      ? moment($('#task_shouldBeFinished').datepicker('getDate')).format('DD/MM/YYYY')
      : ''

    formData.set('task[shouldBeFinished]', formattedData)

    return formData
  },

  create: function (taskId, formData, buttonElement) {
    const self = this

    $.ajax({
      url: Routing.generate('app_ajax_task_create', { id: taskId }),
      type: 'POST',
      data: this.prepareFormAttachments(formData),
      dataType: 'JSON',
      cache: false,
      contentType: false,
      processData: false,
      beforeSend: function () {
        if (typeof buttonElement !== 'undefined') {
          Spinner(buttonElement).prepend()
          self.disableButton(buttonElement)
        }
      },
      success: function (response) {
        if (response.success) {
          if (response.redirect) {
            window.location.href = response.redirect
          }
        } else {
          Spinner(buttonElement).remove()
          self.enableButton(buttonElement)
          useNotifications('error', response.message)
        }
      },
    })
  },

  initUpload: function (form) {
    this.DropZone = new DropZone(form, '.create-task-box')
    this.DropZone.checkContainers()
  },
}

// Declare object/function/variables/constants that can be imported and used in other js files
export { Tasks, Teams }
